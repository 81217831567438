import { FC } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Button } from "@root/shared/components/Button";
import { Link } from "@root/shared/components/Link";
import { AcceptScoutLink } from "@userFrontend/components/Links/AcceptScoutLink";
import { KeepForm } from "@userFrontend/features/publication/components/KeepForm";
import { EntryLink } from "@userFrontend/components/Links/EntryLink";
import { SalonTourButton } from "@userFrontend/components/Buttons/SalonTourButton";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { OiwaiInformationBox } from "@userFrontend/components/OiwaiInfomationBox";
import { ScoutDeadlineInformation } from "@userFrontend/features/publication/components/ScoutDeadlineInformation";
import { ExtendScoutDeadlineButton } from "@userFrontend/components/Buttons/ExtendScoutDeadlineButton";
import { LineConsultButton } from "@userFrontend/components/Buttons/LineConsultButton";
import { ButtonProps } from "@userFrontend/features/publication/components/DetailPageActionButtons/type";
import * as styles from "./styles.css";

export const DefaultStyleButtons: FC<ButtonProps> = ({
  workCode,
  clientShopId,
  isApp,
  isPreview,
  oiwaiInfomationProps,
  entryProps,
  keepProps,
  salonTourProps,
  scoutProps,
  extendScoutProps,
  declineScoutProps,
  lineProps,
}) => {
  const { handleClickSalonTourButton } = useSalonTourButton();
  // 見学応募の見た目
  const salonTourButtonTheme = scoutProps.isScouted ? "secondary" : "tertiary";

  return (
    <div>
      {scoutProps.isScouted && (
        <>
          {oiwaiInfomationProps.isOiwaiDisplay && (
            <OiwaiInformationBox
              recruitmentRewardPrice={oiwaiInfomationProps.recruitmentRewardPrice}
              keepWorkRewardPrice={oiwaiInfomationProps.keepWorkRewardPrice}
            />
          )}
          {extendScoutProps.scoutDeadline && (
            <span className={styles.deadlineWrapper}>
              <ScoutDeadlineInformation remainingDaysNum={extendScoutProps.scoutDeadline} />
            </span>
          )}
        </>
      )}
      <div
        className={!entryProps.hasEntried ? styles.buttonWrapper : ""}
        style={assignInlineVars({
          [styles.buttonMaxWidth]: scoutProps.isScouted ? "184px" : "460px",
        })}
      >
        {entryProps.hasEntried && (
          <div className={styles.hasEntriedWrapper}>
            {lineProps?.isShowLineConsultButton && (
              <div className={styles.lineButtonWrapper}>
                <LineConsultButton
                  gaAction={lineProps.gaAction}
                  gaLabel={lineProps.gaLabel}
                  onClick={lineProps.onClick}
                  operatorAppealFlag={lineProps.isShowLineAppealText}
                />
              </div>
            )}
            <div>
              {oiwaiInfomationProps.isOiwaiDisplay && (
                <OiwaiInformationBox
                  recruitmentRewardPrice={oiwaiInfomationProps.recruitmentRewardPrice}
                  keepWorkRewardPrice={oiwaiInfomationProps.keepWorkRewardPrice}
                />
              )}
              <div className={styles.hasEntriedButtonWrapper}>
                <Button theme="disabled">応募済み</Button>
              </div>
            </div>
          </div>
        )}
        {scoutProps.isScouted && salonTourProps.isSalonTour && (
          <div className={styles.salonTourButtonWrapper}>
            <SalonTourButton
              text="見学を希望する"
              buttonTheme={salonTourButtonTheme}
              onClick={() =>
                handleClickSalonTourButton(
                  isApp,
                  workCode,
                  scoutProps.isScouted,
                  clientShopId,
                  undefined,
                  undefined,
                  salonTourProps.url,
                )
              }
            />
          </div>
        )}
        {!entryProps.hasEntried && (
          <>
            <div className={styles.flexButtonWrapper}>
              <>
                {entryProps.isEnterable && !scoutProps.isScouted && (
                  <>
                    {salonTourProps.isSalonTour && (
                      <div className={styles.salonTourButtonWrapper}>
                        <SalonTourButton
                          text="見学を希望する"
                          buttonTheme={salonTourButtonTheme}
                          onClick={() =>
                            handleClickSalonTourButton(
                              isApp,
                              workCode,
                              scoutProps.isScouted,
                              clientShopId,
                              undefined,
                              undefined,
                              salonTourProps.url,
                            )
                          }
                        />
                      </div>
                    )}
                    <div className={styles.keepButtonWrapper}>
                      <KeepForm
                        isPreview={isPreview}
                        url={keepProps.url}
                        workCode={workCode}
                        hasKeeped={keepProps.hasKeeped}
                        onClick={keepProps.onClick}
                        gaAction={keepProps.gaAction}
                        gaLabel={keepProps.gaLabel}
                      />
                    </div>
                    <div className={styles.entryButtonWrapper}>
                      {oiwaiInfomationProps.isOiwaiDisplay && (
                        <OiwaiInformationBox
                          recruitmentRewardPrice={oiwaiInfomationProps.recruitmentRewardPrice}
                          keepWorkRewardPrice={oiwaiInfomationProps.keepWorkRewardPrice}
                        />
                      )}
                      <EntryLink
                        text="応募画面へ進む"
                        firebaseAction={entryProps.firebaseAction}
                        url={entryProps.url}
                        onClick={entryProps.onClick}
                        gaAction={entryProps.gaAction}
                        gaLabel={entryProps.gaLabel}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            {lineProps?.isShowLineConsultButton && (
              <div className={styles.lineButtonWrapper}>
                <LineConsultButton
                  gaAction={lineProps.gaAction}
                  gaLabel={lineProps.gaLabel}
                  onClick={lineProps.onClick}
                  operatorAppealFlag={lineProps.isShowLineAppealText}
                />
              </div>
            )}
          </>
        )}
        {scoutProps.isScouted && scoutProps.url && (
          <div className={styles.acceptButtonWrapper}>
            <AcceptScoutLink text="スカウトを受ける" url={scoutProps.url} workCode={workCode} />
          </div>
        )}
        {scoutProps.isScouted && (
          <div className={styles.scoutExtendDeclineWrapper}>
            {scoutProps.isScouted && extendScoutProps.isScoutExtend && (
              <div className={styles.isScoutextendButtonWrapper}>
                <ExtendScoutDeadlineButton
                  gaAction={extendScoutProps.gaAction}
                  gaLabel={extendScoutProps.gaLabel}
                  onClick={extendScoutProps.onClick}
                >
                  <p>
                    スカウト期限
                    <br />
                    延長する
                  </p>
                </ExtendScoutDeadlineButton>
              </div>
            )}
            {scoutProps.isScouted && !extendScoutProps.isScoutExtend && (
              <div className={styles.isScoutextendButtonWrapper}>
                <Button theme="disabled">
                  スカウト期限
                  <br />
                  延長済み
                </Button>
              </div>
            )}
            {scoutProps.isScouted && (
              <div className={styles.declineButtonWrapper}>
                <Link
                  theme="tertiary"
                  href={declineScoutProps.url}
                  className="js-scout-decline"
                  data-ga-action={declineScoutProps.gaAction}
                  data-ga-label={declineScoutProps.gaLabel}
                >
                  辞退する
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
